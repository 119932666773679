import { Builder } from 'builder-pattern';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { GetAllBannerByPageUsecase } from '@modules/banner/application/usecases/banner.usecase';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { IBannerList } from '@shared/models/banner.model';
import { BannerRepositoryImpl } from '../../inbound/repositories/banner.repository';
export interface BannerState {
  bannerList: IBannerList;
  setBannerList: (page: string) => Promise<void>;
}
export const bannerStore = create<BannerState>()(devtools(set => ({
  bannerList: Builder<IBannerList>().highlight([]).bottom([]).build(),
  setBannerList: async (page): Promise<void> => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const bannerRepositoryImpl = new BannerRepositoryImpl();
      const getAllBannerByPageUsecase = new GetAllBannerByPageUsecase(bannerRepositoryImpl);
      const result = await getAllBannerByPageUsecase.handle(page);
      if (result.isRight()) {
        set(() => ({
          bannerList: result.value
        }));
      }
    } catch (error) {
      console.error('Error setProductMainBannerStore', error);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  }
})));