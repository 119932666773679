import { FC, useEffect } from 'react';
import Image from 'next/image';
import Cookies from 'js-cookie';
import { isEmpty } from 'radash';
import { RegisterBannerType } from '@modules/libs/graphql/generated/graphql';
import { registerBannerStore } from '@modules/registerBanner/adapter/outbound/stores/registerBanner.store';
import { Content } from '@components/layouts';
import { getAuthCookiesName } from '@shared/helpers/getAuthCookiesName.helper';
import { useWindowSize } from '@shared/utils/hooks';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import breakpoint from '../../../shared/assets/breakpoint.json';
import { BannerRegister, ImgBanner, ReflexShadow } from './RegisterBanner.style';
const RegisterBannerLogic: FC = () => {
  const utag = useUtag();
  const {
    width
  } = useWindowSize();
  const token = Cookies.get(getAuthCookiesName());
  const {
    registerBanner,
    setRegisterBanner
  } = registerBannerStore(state => state);
  useEffect(() => {
    if (token) {
      setRegisterBanner(RegisterBannerType.BannerPlayGame);
    } else {
      setRegisterBanner(RegisterBannerType.BannerRegister);
    }
  }, [token]);
  const handleOnClickTracker = (title: string) => {
    utag.link({
      tealium_event: 'banner_register_click',
      banner_name: title
    });
  };
  const banner = registerBanner[0];
  return !isEmpty(banner) && <Content>
                <BannerRegister onClick={() => {
      handleOnClickTracker('banner_register');
    }}>
                    <a href={banner.linkOutUrl} target="_blank" aria-label="register banner">
                        <ImgBanner src={width > breakpoint.md - 1 ? banner.bannerDesktop : banner.bannerMobile} width={1140} height={440} alt={banner.title} />
                        <ReflexShadow>
                            <Image src={width > breakpoint.md - 1 ? banner.bannerDesktop : banner.bannerMobile} width={1140} height={440} alt={banner.title} />
                        </ReflexShadow>
                    </a>
                </BannerRegister>
            </Content>;
};
export default RegisterBannerLogic;