import { RegisterBannerResponse } from '@modules/libs/graphql/generated/graphql';
import { IRegisterBanner } from '@modules/registerBanner/domain/models/registerBanner.model';
import { getLinkoutPrefixHttps } from '@shared/helpers/linkout.helper';
export class RegisterBannerEntityMapper {
  public static toDomainList(registerBannerList: RegisterBannerResponse[]): IRegisterBanner[] {
    return registerBannerList?.map(registerBan => this.toDomain(registerBan));
  }
  public static toDomain({
    id,
    title,
    bannerDesktop,
    bannerMobile,
    linkoutUrl
  }: RegisterBannerResponse): IRegisterBanner {
    return {
      id: id.toString(),
      title,
      bannerDesktop,
      bannerMobile,
      linkOutUrl: getLinkoutPrefixHttps(linkoutUrl)
    };
  }
}